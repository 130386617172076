// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-templates-annonce-js": () => import("./../../../src/templates/annonce.js" /* webpackChunkName: "component---src-templates-annonce-js" */),
  "component---src-templates-page-annonces-js": () => import("./../../../src/templates/page-annonces.js" /* webpackChunkName: "component---src-templates-page-annonces-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page-contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

